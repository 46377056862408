import request from './Axios'

/**
 * 登录
 * @param {*} data
 * @returns
 */
export const user_login = data => {
  return request({
    url: '/user/admin/login',
    method: 'post',
    data
  })
}

/**
 * 登录
 * @param {*} data
 * @returns
 */
export const user_logout = () => {
  return request({
    url: '/user/logout',
    method: 'post',
  })
}



// 常量数据
export const constantQuery = params => {
  return request({
    url: `/constant/query/${params.className}`,
    method: 'get'
  })
}

// 获取菜单
export const permissionMenus = () => {
  return request({
    url: '/permission/list-menus',
    method: 'get',
  })
}


// 获取用户信息
export const getUser = () => {
  return request({
    url: '/user/get',
    method: 'get',
  })
}