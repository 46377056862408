import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getToken } from '../utils/auth'
import router from '@/router'
import qs from "qs"
const service = axios.create({
  baseURL:
    process.env.NODE_ENV == 'development'
      ? 'https://www.hxupan.cn/zrwp/api/'
      : 'https://www.wewin.ink/api',
  timeout: 20000
})

// 发起请求之前的拦截器
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (config.url.indexOf('/delete') != -1) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded	'
    }
    if (config.url == '/coupon/send' || config.url == '/coupon/uploadExcel') {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    if (getToken()) {
      config.headers['token'] = getToken()
    }
    return config
  },
  error => Promise.reject(error)
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.data.code == 5003) {
      ElMessage.error('登录过期')
      localStorage.clear()
      router.push('/login')
    } else {
      ElMessage.error('系统错误')
    }
    return Promise.reject(error)
  }
)

export default service
