import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    meta: {
      title: '404'
    }
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: '/404'
  // },
  {
    path: '/',
    component: () => import('@/layout/index.vue'),
    name: 'layout',
    children: [],
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})




export default router
