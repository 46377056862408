const TokenKey = 'token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function clearToken() {
  return localStorage.removeItem(TokenKey)
}

const menuKey = 'menu'
export function setMenu (menu) {
  return localStorage.setItem(menuKey, menu)
}
export function getMenu () {
  return localStorage.getItem(menuKey)
}
export function clearMenus () {
  return localStorage.removeItem(menuKey)
}
