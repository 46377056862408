import {permissionMenus} from '@/api/user'
import router from '@/router'
import {clearMenus, getMenu, setMenu} from '@/utils/auth'
import {ElMessage} from "element-plus";

const appModule = {
  namespaced: true,
  state: {
    device: 'desktop',
    size: 'medium',
    sidebar: {
      opened: true,
      withoutAnimation: false
    },
    menu: [],
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_SIZE: (state, size) => {
      state.size = size
    },
    SET_MENU: (state, val) => {
      state.menu = val
      setMenu(JSON.stringify(val))
    },
    CLEAR_MENU: (state) => {
      state.menu = ''
      clearMenus()
    },
    ADD_MENU: () => {
      if (!getMenu()) {
        return
      } else {
        getAsyncRoutes()
      }
    },
    SET_BTN: (state, list) => {
      state.btnList = list
    }

  },
  actions: {
    toggleSideBar({commit}) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({commit}, withoutAnimation) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({commit}, device) {
      commit('TOGGLE_DEVICE', device)
    },
    setSize({commit}, size) {
      commit('SET_SIZE', size)
    },
    setMenu({commit}, menu) {
      commit('SET_MENU', menu)
    },
    menuLists({commit}) {
      permissionMenus().then(({data: res}) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            const rotueLists = handleList(res.data)
            console.log(rotueLists)
            commit('CLEAR_MENU')
            commit('SET_MENU', rotueLists)
            // commit('ADD_MENU', router)
            if (router.currentRoute.value.fullPath == '/') {
              router.push({path: rotueLists[0].children[0].path})
            } else {
              if (JSON.stringify(router.currentRoute.value.query) == '{}') {
                router.push({path: router.currentRoute.value.fullPath})
              } else {
                router.push({path: router.currentRoute.value.fullPath, query: router.currentRoute.value.query})
              }
            }
          }

        } else {
          ElMessage.error(res.msg)
        }
      })
    }
  }
}

export default appModule


const handleList = (arr, nodePath) => {
  let routes = []
  arr.forEach((item,index) => {
    let page = {
      path: '/' + item.path,
      name: item.path,
      meta: {title: item.name, hidden: !item.visible},
      nodePath: nodePath ? nodePath : ''
    }
    if (item.children) {
      page.children = handleList(item.children,nodePath ? nodePath + '/' + item.path : '/' + item.path)
      if(index === 0){
        page.children[0].meta.affix = true
      }
    } else {
      page.url = '../../views' + page.nodePath + page.path + '.vue'
      page.component = () => import('../../views' + page.nodePath + page.path + '.vue') || ''
      router.addRoute('layout', page)
    }
    routes.push(page)
  })
  return routes
}

const getAsyncRoutes = () => {
  const menu = JSON.parse(getMenu() || '[]')
  let menuArray = []
  menu.forEach((item) => {
    if (item.children) {
      let items = item
      item.children = item.children.map((item) => {
        item.component = () =>
          import(`@/views${items.path}${item.path}.vue`)
        return item
      })
      menuArray.push(...item.children)
    } else {
      item.component = () => import(`@/views/${item.name}/index.vue`)
      menuArray.push(item)
    }
  })
  menuArray.forEach((item) => {
    router.addRoute('layout', item)
  })
}



