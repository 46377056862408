import { configure, start, done } from 'nprogress'
import 'nprogress/nprogress.css'
import router from './router'
import { getMenu, getToken } from './utils/auth'
import store from '@/store'

configure({ showSpinner: false })

router.beforeEach(async (to) => {
  start()
  const hasName = getToken()
  if (to.path !== '/login' && !hasName) {
    done()
    return '/login'
  } else if (hasName && to.path !== '/login') {
    done()
    if (router.getRoutes().length == 3 || !getMenu()) {
      store.dispatch('app/menuLists')
    }
  } else if (hasName && to.path === '/login' && getMenu()) {
    done()
    return '/'
  }
})
router.afterEach(() => {
  done()
})
